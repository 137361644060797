import { MenuItem, Select } from '@material-ui/core';
import { FieldProps } from 'formik';
import React, { FC } from 'react';
import { ListBox } from '../../listBox/ListBox';
import { theme } from '../../../theme/Theme';
import { useStyles } from './MiddysSelectStyles';
import MiddysErrorMessage from '../MiddysErrorMessage';

export interface MiddysSelectOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface MiddysSelectProps extends FieldProps {
  options: MiddysSelectOption[];
  placeholder?: string;
}

const MiddysSelect: FC<MiddysSelectProps> = ({ options, field, placeholder, ...props }) => {
  const classes = useStyles();
  return (
    <ListBox width="100%" spacing={2}>
      <Select
        displayEmpty={!!placeholder}
        inputProps={{ classes: { root: classes.select } }}
        disableUnderline
        variant="filled"
        {...field}
        {...props}
        renderValue={
          placeholder
            ? (value: any) =>
                value?.length ? (
                  Array.isArray(value) ? (
                    value.join(', ')
                  ) : (
                    value
                  )
                ) : (
                  <span style={{ color: theme.colors.textSecondary }}>{placeholder}</span>
                )
            : undefined
        }
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <MiddysErrorMessage name={field.name} />
    </ListBox>
  );
};

export default MiddysSelect;
