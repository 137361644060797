import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CustomerLandingStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import { routes } from '../../Routes';
import {
  HIGHLIGHTED_PACKAGES,
  LABELS,
  ROOMS,
  STEPS,
  STEPS_SMALL,
} from './CustomerLandingConstants';
import { BookingActions } from '../../store/actions/BookingActions';
import MiddysFooter from '../../components/middysFooter/MiddysFooter';
import { BundlePackage, OfferCategory } from '../../models/bundles/Bundles';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import homeBanner from '../../assets/hero/middys2.jpg';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ActionButton from '../../components/actionButton/ActionButton';
import { ListBox } from '../../components/listBox/ListBox';
import Button from '../../components/button/Button';
import FormattedText from '../../components/formattedText/FormattedText';
import FullWidthBlueBanner from '../../components/fullWidthBlueBanner/FullWidthBlueBanner';
import ContentContainer from '../../components/containers/ContentContainer';
import NewsLetterForm from './newsLetterForm/NewsLetterForm';
import SectionContent from '../../components/sectionContent/SectionContent';
import whyChooseImage from '../../assets/middysAssets/electrician2.png';
import { WHY_CHOOSE } from '../bundleDetails/BundleDetailsConstants';

interface CustomerLandingProps extends RouteComponentProps {
  bundles: BundlePackage[] | undefined;
  loadingBundles: boolean;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updatePostcode: (data: string) => void;
}

const CustomerLanding: FC<CustomerLandingProps> = ({
  history,
  bundles,
  loadingBundles,
  accessToken,
  getBundlesRequest,
  updatePostcode,
}) => {
  const styles = useStyles();

  useEffect(() => {
    getBundlesRequest();
    updatePostcode('');
  }, [accessToken]);

  const packages = useMemo<OfferCategory[]>(() => {
    const bundlePackages = bundles && bundles.length > 0 ? bundles : [];
    let result: OfferCategory[] = [];
    bundlePackages
      .filter((p) => !!p.category)
      .map((p) => {
        if (!result.some((obj) => obj.title === p.category!.title)) {
          result.push(p.category!);
        }
      });

    return result;
  }, [bundles]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <Header />
      <BackgroundCover
        text={LABELS.PAGE_SUBTITLE}
        landing
        loading={loadingBundles}
        backgroundImage={homeBanner}
        imgPosition="right bottom"
        invertImage
      ></BackgroundCover>
      <div className={styles.content}>
        <ContentContainer>
          <Box my={4}>
            <HowItWorksSection
              altStyle
              steps={isSmall ? STEPS_SMALL : STEPS}
              title={LABELS.HOW_IT_WORKS}
            />

            <div className={styles.servingAdelaideContainer}>
              <ListBox spacing={8}>
                <Typography className="title" variant="h3" color="primary">
                  {LABELS.ADELAIDE_HEADING}
                </Typography>
                <div className={styles.metroTextContainer}>
                  <Typography>{LABELS.METRO_SECTION_DESCRIPTION}</Typography>
                </div>
                <Box display="flex" justifyContent="center">
                  <Button handlePress={() => {}}>FAQs</Button>
                </Box>
              </ListBox>
            </div>
          </Box>

          <div className={styles.section}>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gridRowGap="1em"
            >
              <Typography variant="h2" className="title" color="secondary">
                {LABELS.ABOUT_TITLE}
              </Typography>
              <ActionButton
                variant="outlined"
                onClick={() => {
                  history.push(routes.packages);
                }}
              >
                {LABELS.VIEW_ALL}
              </ActionButton>
            </Box>
            <div className={styles.description}>
              <FormattedText>{LABELS.ABOUT_DESCRIPTION}</FormattedText>
            </div>
            <div className={styles.offersGrid}>
              {!bundles || loadingBundles ? (
                <>
                  {[...Array(6).fill(null)].map((_, idx) => (
                    <BundleCardLoading key={idx} />
                  ))}
                </>
              ) : (
                <>
                  {HIGHLIGHTED_PACKAGES.map((p, idx) => (
                    <div
                      className={styles.categoryContainer}
                      key={idx}
                      onClick={() =>
                        history.push({ pathname: routes.packages, search: `?filter=${p.name}` })
                      }
                    >
                      <div className={styles.imageContainer}>
                        <img
                          style={{
                            objectPosition: 'top',
                          }}
                          src={p.image}
                          className={styles.image}
                          alt="bundle"
                        />
                      </div>
                      <div className={styles.categoryContentContainer}>
                        <div className={styles.categoryTitle}>{p.name}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </ContentContainer>
        <FullWidthBlueBanner
          heading={LABELS.BANNER_HEADING}
          subheading={LABELS.BANNER_SUBHEADING}
          buttonLabel={LABELS.BANNER_BUTTON_LABEL}
          buttonAction={() => {
            history.push(routes.registerInterest);
          }}
        />
        <ContentContainer>
          <ListBox mt={4} spacing={10}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h2" className="title" color="secondary">
                {LABELS.SOLUTIONS_HEADING}
              </Typography>
              <Button handlePress={() => {}} secondary>
                {LABELS.VIEW_ALL}
              </Button>
            </Box>
            <div className={styles.solutionsContainer}>
              {ROOMS.map((room, idx) => (
                <div
                  className={styles.categoryContainer}
                  key={idx}
                  onClick={() =>
                    history.push({ pathname: routes.packages, search: `?filter=${room.name}` })
                  }
                >
                  <div className={styles.imageContainer}>
                    <img src={room.image} className={styles.image} alt="bundle" />
                  </div>
                  <div className={styles.categoryContentContainer}>
                    <div className={styles.categoryTitle}>{room.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <NewsLetterForm />
            <SectionContent
              title={LABELS.WHY_CHOOSE}
              image={whyChooseImage}
              reversed
              headingPrimary
              imgFill
              disableTopMargin
            >
              {WHY_CHOOSE.map((why) => (
                <>
                  <div className={styles.stepTitle}>{why.title}</div>
                  <div className={styles.stepText}>{why.text}</div>
                </>
              ))}
            </SectionContent>
          </ListBox>
        </ContentContainer>
      </div>
      {bundles && <MiddysFooter packages={bundles} />}
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerLanding));
