import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { theme } from '../../theme/Theme';

interface FieldLabelProps {
  label: string;
  required?: boolean;
}

const FieldLabel: FC<FieldLabelProps> = ({ label, required }) => {
  return (
    <Box mb={1}>
      <Typography
        color="textSecondary"
        variant="body2"
        style={{ fontWeight: 500, fontFamily: theme.font.family2 }}
      >
        {label}
        {required ? ' *' : ''}
      </Typography>
    </Box>
  );
};

export default FieldLabel;
