import React from 'react';
import { Field, FieldProps } from 'formik';
import { styled, Theme } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import MiddysErrorMessage from './MiddysErrorMessage'; // Adjust the import path as necessary
import FieldLabel from '../fieldLabel/FieldLabel';

interface MiddysTextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  required?: boolean;
  altStyle?: boolean;
}

const StyledField = styled('input')(
  ({ theme: _theme, ...props }: { theme: Theme; altStyle?: boolean }) => ({
    background: props.altStyle ? 'white' : theme.colors.inputBackground,
    padding: 12,
    outline: 'none',
    border: 'none',
    fontSize: theme.font.m,
    width: '100%',
    boxSizing: 'border-box',
  }),
);

const CustomInputComponent: React.FC<
  FieldProps & React.InputHTMLAttributes<HTMLInputElement> & { altStyle?: boolean }
> = ({ field, altStyle, ...props }) => {
  return <StyledField {...field} {...props} altStyle={altStyle} />;
};

const MiddysTextField: React.FC<MiddysTextFieldProps> = ({
  label,
  required,
  name,
  altStyle,
  ...props
}) => (
  <div style={{ width: '100%' }}>
    {label && <FieldLabel required={required} label={label} />}
    <Field name={name} altStyle={altStyle} component={CustomInputComponent} {...props} />
    <MiddysErrorMessage name={name} />
  </div>
);

export default MiddysTextField;
