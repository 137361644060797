import { theme } from '../../theme/Theme';
import { makeStyles, Theme } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { loadingAnimation } from '../../theme/GlobalStyles';

interface StyleProps {
  altStyle?: boolean;
  isSmall?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  howItWorksContainer: {
    paddingTop: theme.units.padding * 8,
    marginBottom: theme.units.margin * 8,
  },
  howItWorksTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    textAlign: 'center',
    fontWeight: 500,
    color: theme.colors.primary,
    marginBottom: theme.units.margin * 12,
    textTransform: 'capitalize',
  },
  howItWorksSteps: {
    display: 'grid',
    gridGap: theme.units.margin * 8,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  howItWorksStep: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'row',
      gap: '2em',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  step: ({ altStyle, isSmall }) => ({
    width: isSmall ? 50 : 70,
    height: isSmall ? 50 : 70,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.font.family,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: altStyle ? theme.colors.secondary : theme.colors.light,
    backgroundColor: altStyle ? 'white' : theme.colors.buttonPrimary,
    border: altStyle ? `2px solid ${theme.colors.secondary}` : 'unset',
  }),
  stepTitle: ({ isSmall }) => ({
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    fontWeight: 500,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 4,
    marginTop: theme.units.margin * 5,
    textAlign: isSmall ? 'left' : 'center',
  }),
  stepText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    textAlign: 'center',
    lineHeight: 1.6,
  },
  // Loading
  loadingHowItWorksTitle: {
    marginBottom: theme.units.margin * 12,
    width: 200,
    height: theme.units.iconHeight * 1.5,
    margin: 'auto',
    ...loadingAnimation,
  },
  loadingStep: {
    width: 70,
    height: 70,
    borderRadius: 5000,
    ...loadingAnimation,
  },
  loadingStepTitle: {
    marginBottom: theme.units.margin * 4,
    marginTop: theme.units.margin * 5,
    width: 140,
    height: theme.units.iconHeight,
    ...loadingAnimation,
  },
  loadingStepText: {
    width: '100%',
    height: 240,
    ...loadingAnimation,
  },
});
