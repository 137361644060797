import { MiddysSelectOption } from './components/middysFields/middysSelect/MiddysSelect';

export {};
export const SIZES = {
  xs: 0,
  sm: 560,
  md: 780,
  lg: 1024,
  xl: 1366,
};

export const AUSTRALIAN_STATES = [
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
  'Australian Capital Territory',
  'Northern Territory',
];

export const STATE_OPTIONS: MiddysSelectOption[] = AUSTRALIAN_STATES.map((s) => ({
  label: s,
  value: s,
}));

export const TERMS_LINK =
  'https://media.middys.com.au/staging/middys/media/media/legaldocuments/privacy-policy.pdf';

export const TERMS_LABEL = 'I have read and accept the ';
