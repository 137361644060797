import React from 'react';
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { styled } from '@material-ui/core';
import { theme } from '../../theme/Theme';

const StyledErrorMessage = styled('div')({
  color: theme.colors.error,
  fontSize: 12,
  fontWeight: 500,
});

interface MiddysErrorMessageProps extends ErrorMessageProps {}

const MiddysErrorMessage: React.FC<MiddysErrorMessageProps> = (props) => (
  <ErrorMessage {...props}>{(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}</ErrorMessage>
);

export default MiddysErrorMessage;
