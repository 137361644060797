import React, { FC } from 'react';
import { useStyles } from './NewsLetterFormStyles';
import Box from '@material-ui/core/Box';
import { ListBox } from '../../../components/listBox/ListBox';
import { Typography } from '@material-ui/core';
import { LABELS } from './NewsLetterFormConstants';
import { Formik } from 'formik';
import MiddysTextField from '../../../components/middysFields/MiddysTextField';
import Button from '../../../components/button/Button';
import { theme } from '../../../theme/Theme';
import { TERMS_LABEL, TERMS_LINK } from '../../../Constants';

interface NewsLetterFormProps {}

const NewsLetterForm: FC<NewsLetterFormProps> = () => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <ListBox mb={4} spacing={3}>
        <Typography variant="h3" className="title" style={{ color: theme.colors.secondary }}>
          {LABELS.heading}
        </Typography>
        <Typography style={{ fontSize: 14 }} color="textSecondary">
          {LABELS.subheading}
        </Typography>
      </ListBox>
      <Formik initialValues={{ firstName: '', email: '' }} onSubmit={() => {}}>
        <ListBox spacing={4}>
          <Box display="flex" gridGap="1em">
            <MiddysTextField
              required
              label="First Name"
              placeholder="First Name"
              name="firstName"
              altStyle
            />
            <MiddysTextField
              required
              label="Email"
              placeholder="Email Address"
              name="email"
              altStyle
            />
          </Box>
          <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
            <Typography color="textSecondary" variant="h6">
              {TERMS_LABEL}
              <a
                style={{ color: theme.colors.secondary }}
                target="_blank"
                href={TERMS_LINK}
                rel="noopener noreferrer"
              >
                <span color={theme.colors.textSecondary}>
                  <u>Middy's Privacy Policy</u>
                </span>
              </a>
            </Typography>
            <Button handlePress={() => {}}>{LABELS.buttonLabel}</Button>
          </Box>
        </ListBox>
      </Formik>
    </Box>
  );
};

export default NewsLetterForm;
