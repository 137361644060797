import React, { FC, useEffect, useState } from 'react';
import * as RegisterInterestFormConstants from './RegisterInterestFormConstants';
import { Field, Formik } from 'formik';
import { Grid, Typography, Checkbox, Box, FormControlLabel } from '@material-ui/core';
import FieldLabel from '../../components/fieldLabel/FieldLabel';
import { ListBox } from '../../components/listBox/ListBox';
import MiddysTextField from '../../components/middysFields/MiddysTextField';
import MiddysSelect from '../../components/middysFields/middysSelect/MiddysSelect';
import { STATE_OPTIONS, TERMS_LABEL, TERMS_LINK } from '../../Constants';
import MiddysLink from '../../components/link/MiddysLink';
import { theme } from '../../theme/Theme';
import ActionButton from '../../components/actionButton/ActionButton';
import BookingService, { RegisterFormType } from '../../services/booking/BookingService';
import SuccessDialog from './SuccessDialog';

interface RegisterInterestFormProps {
  type: RegisterFormType;
}

const RegisterInterestForm: FC<RegisterInterestFormProps> = ({ type }) => {
  const { registerInterest, isLoading, isSuccess } = BookingService.useRegisterInterest(type);
  const [accepted, setAccepted] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setAccepted(false);
      setShowSuccessDialog(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Formik
        initialValues={RegisterInterestFormConstants.INITIAL_VALUES}
        onSubmit={(values, { resetForm }) => {
          registerInterest(values);
          resetForm();
        }}
        validationSchema={RegisterInterestFormConstants.VALIDATION_SCHEMA}
      >
        {({ handleSubmit }) => (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <MiddysTextField
                  required
                  label={RegisterInterestFormConstants.LABELS.firstName}
                  placeholder={RegisterInterestFormConstants.LABELS.firstName}
                  name={RegisterInterestFormConstants.FIELDS.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MiddysTextField
                  required
                  label={RegisterInterestFormConstants.LABELS.lastName}
                  placeholder={RegisterInterestFormConstants.LABELS.lastName}
                  name={RegisterInterestFormConstants.FIELDS.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MiddysTextField
                  required
                  label={RegisterInterestFormConstants.LABELS.email}
                  placeholder={RegisterInterestFormConstants.LABELS.email}
                  name={RegisterInterestFormConstants.FIELDS.email}
                  type="email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MiddysTextField
                  required
                  label={RegisterInterestFormConstants.LABELS.contact}
                  placeholder={RegisterInterestFormConstants.LABELS.contact}
                  name={RegisterInterestFormConstants.FIELDS.contact}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldLabel required label={RegisterInterestFormConstants.LABELS.state} />
                <Field
                  placeholder={RegisterInterestFormConstants.LABELS.state}
                  name={RegisterInterestFormConstants.FIELDS.state}
                  options={STATE_OPTIONS}
                  component={MiddysSelect}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MiddysTextField
                  required
                  label={RegisterInterestFormConstants.LABELS.postcode}
                  placeholder={RegisterInterestFormConstants.LABELS.postcode}
                  name={RegisterInterestFormConstants.FIELDS.postcode}
                />
              </Grid>

              {/* Room types preferences */}
              <Grid style={{ paddingBottom: 0 }} item xs={12}>
                <FieldLabel label={RegisterInterestFormConstants.LABELS.rooms} />
              </Grid>
              {RegisterInterestFormConstants.ROOM_TYPE_OPTIONS.map((rt) => (
                <Grid style={{ paddingTop: 0, paddingBottom: 0 }} item sm={4} md={3} xs={12}>
                  <Field
                    as={FormControlLabel}
                    type="checkbox"
                    name={RegisterInterestFormConstants.FIELDS.rooms}
                    value={rt.value}
                    label={rt.label}
                    control={<Checkbox />}
                  />
                </Grid>
              ))}
              <Grid style={{ paddingTop: 10 }} item xs={12}>
                <MiddysTextField
                  placeholder={RegisterInterestFormConstants.LABELS.otherRoom}
                  label={RegisterInterestFormConstants.LABELS.otherRoom}
                  name={RegisterInterestFormConstants.FIELDS.otherRoomPreference}
                />
              </Grid>

              {/* Upgrades */}
              <Grid style={{ paddingBottom: 0 }} item xs={12}>
                <FieldLabel label={RegisterInterestFormConstants.LABELS.upgrades} />
              </Grid>
              {RegisterInterestFormConstants.UPGRADE_OPTIONS.map((ug) => (
                <Grid style={{ paddingTop: 0, paddingBottom: 0 }} item sm={12} md={6}>
                  <Field
                    as={FormControlLabel}
                    type="checkbox"
                    name={RegisterInterestFormConstants.FIELDS.upgrades}
                    value={ug.value}
                    label={ug.label}
                    control={<Checkbox />}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <FieldLabel label={RegisterInterestFormConstants.LABELS.timeframe} />
                <Field
                  placeholder={RegisterInterestFormConstants.LABELS.timeframe}
                  name={RegisterInterestFormConstants.FIELDS.timeframe}
                  options={RegisterInterestFormConstants.TIMEFRAME_OPTIONS}
                  component={MiddysSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <ListBox alignItems="center" direction="row" spacing={1}>
                  <Checkbox
                    checked={accepted}
                    onChange={(e) => {
                      setAccepted(e.target.checked);
                    }}
                  />
                  <Typography color="textSecondary" variant="h6">
                    {TERMS_LABEL}
                    <a
                      style={{ color: theme.colors.secondary }}
                      target="_blank"
                      href={TERMS_LINK}
                      rel="noopener noreferrer"
                    >
                      <span color={theme.colors.textSecondary}>
                        <u>Middy's Privacy Policy</u>
                      </span>
                    </a>
                  </Typography>
                </ListBox>
              </Grid>
              <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <ActionButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isLoading || !accepted}
                  >
                    {RegisterInterestFormConstants.LABELS.submit}
                  </ActionButton>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
      {showSuccessDialog && (
        <SuccessDialog
          onDone={() => {
            setShowSuccessDialog(false);
          }}
          type={type}
        />
      )}
    </>
  );
};

export default RegisterInterestForm;
