import * as Yup from 'yup';
import { MiddysSelectOption } from '../../components/middysFields/middysSelect/MiddysSelect';
import { enumToOptions } from '../../helpers/helperFunctions';

export const LABELS = {
  heading: 'Get a free quote',
  subheading: 'Your details',
};

export const FIELD_LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  mobile: 'Mobile',
  email: 'Email',
  address: 'Address',
  interestedIn: 'Interested in',
  notes: 'Notes (optional)',
  timeframe: 'Timeframe',
};

export const FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  mobile: 'mobile',
  email: 'email',
  address: 'address',
  interest: 'interest',
  notes: 'notes',
  timeframe: 'timeframe',
};

export enum TimeFrame {
  LessThanSixMonths = 'Less than 6 Months',
  SixToTwelveMonths = '6-12 Months',
  TwelveToTwentyFourMonths = '12-24 Months',
  TwoPlusYears = '2+ Years',
  Unsure = 'Unsure',
}

export const TIMEFRAME_OPTIONS = enumToOptions(TimeFrame);

export const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  address: Yup.string().required('Address is required'),
  interest: Yup.string().required('Please select at least one interest'),
  timeframe: Yup.string()
    .typeError('Please select a timeframe in which you expect the work to be done')
    .required('Please select a timeframe in which you expect the work to be done'),
});

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  interest: '',
  timeframe: null,
};

export const INTEREST_OPTIONS: MiddysSelectOption[] = [
  ...['Fans', 'Electric Switchboard', 'Lights', 'Solar', 'EV Charging'].map((s) => ({
    label: s,
    value: s,
  })),
  { label: 'General Electric Services (Please Specify)', value: 'General Electric Services' },
];
