import React, { FC, useState } from 'react';
import { useStyles } from './BackgroundCoverStyles';
import { theme as middysTheme } from '../../theme/Theme';
import BackgroundCoverLoading from './BackgroundCoverLoading';
import QuoteForm from '../../containers/quoteForm/QuoteForm';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SIZES } from '../../Constants';
import { ListBox } from '../listBox/ListBox';
import { LABELS } from './BackgroundCoverConstants';
import { ArrowDownward, ArrowDropDownCircle, Close } from '@material-ui/icons';
import ContentContainer from '../containers/ContentContainer';

interface BackgroundCoverProps {
  darken?: boolean;
  landing?: boolean;
  inset?: boolean;
  title?: string;
  text?: string;
  children?: React.ReactNode;
  backgroundImage?: string;
  loading?: boolean;
  imgPosition?: string;
  form?: boolean;
  content?: React.ReactNode;
  invertImage?: boolean;
}

const BackgroundCover: FC<BackgroundCoverProps> = ({
  darken,
  inset,
  title,
  text,
  landing,
  children,
  backgroundImage,
  loading,
  imgPosition,
  invertImage,
}) => {
  const [collapsedQuoteForm, setCollapsedQuoteForm] = useState(true);

  const theme = useTheme();
  const styles = useStyles({ imgPosition, landing, invertImage, backgroundImage });
  const isSmall = useMediaQuery<Theme>(theme.breakpoints.down('xs'));

  return (
    <>
      {loading ? (
        <BackgroundCoverLoading landing={landing} inset={inset} children={children} />
      ) : (
        <div className={styles.backgroundContainer}>
          {landing ? (
            <div className={styles.backgroundWrapper}>
              <div className={styles.trapezoid}></div>
              {/* <img
                src={backgroundImage || theme.hero}
                className={styles.backgroundImage}
                alt="hero"
              /> */}
              <div className={styles.contentContainer}>
                <div className={styles.textWrapper}>
                  <div className={styles.headline}>{title || "Middy's Home Services"}</div>
                  <div className={styles.introText}>
                    {text || 'Whilst supporting our electrical contractors'}
                  </div>
                  {isSmall && collapsedQuoteForm && (
                    <div
                      onClick={() => {
                        setCollapsedQuoteForm(false);
                      }}
                      className={styles.collapsedFormContainer}
                    >
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <ListBox spacing={2}>
                          <Typography className="title" variant="h3" color="secondary">
                            {LABELS.getQuote}
                          </Typography>
                          <Typography color="textSecondary">{LABELS.tapHere}</Typography>
                        </ListBox>
                        <div className={styles.arrowContainer}>
                          <ArrowDownward
                            style={{ color: middysTheme.colors.light, fontSize: 30 }}
                          />
                        </div>
                      </Box>
                    </div>
                  )}
                </div>
                {!isSmall && (
                  <div className={styles.formWrapper}>
                    <QuoteForm />
                  </div>
                )}
                {isSmall && !collapsedQuoteForm && (
                  <Dialog style={{ paddingTop: 40 }} open fullScreen>
                    <AppBar style={{ background: middysTheme.colors.secondary }}>
                      <Toolbar>
                        <ContentContainer
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            paddingRight: 0,
                          }}
                        >
                          <Close
                            onClick={() => {
                              setCollapsedQuoteForm(true);
                            }}
                            style={{ color: 'white', cursor: 'pointer' }}
                          />
                        </ContentContainer>
                      </Toolbar>
                    </AppBar>
                    <div className={styles.formWrapper}>
                      <QuoteForm />
                    </div>
                  </Dialog>
                )}
              </div>
            </div>
          ) : (
            <img
              src={backgroundImage || middysTheme.hero}
              className={styles.backgroundImage2}
              alt="hero"
            />
          )}

          {darken && <div className={styles.darken} />}

          {!!children && <div className={styles.children}>{children}</div>}
          {/* <div className={inset ? styles.inset : styles.contentStart} /> */}
        </div>
      )}
    </>
  );
};

export default BackgroundCover;
