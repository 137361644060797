import fans from '../../assets/customerLandingImages/fans.jpg';
import lights from '../../assets/customerLandingImages/lights.png';
import charging from '../../assets/customerLandingImages/charging.png';
import switchboard from '../../assets/customerLandingImages/switchboard.png';
import electrical from '../../assets/customerLandingImages/electrical.png';
import solar from '../../assets/customerLandingImages/solar.png';
import kitchen from '../../assets/customerLandingImages/kitchen.png';
import bathroom from '../../assets/customerLandingImages/bathroom.png';
import outside from '../../assets/customerLandingImages/outside.png';
import smartHome from '../../assets/customerLandingImages/smart.png';

export const LABELS = {
  PAGE_TITLE: "Middy's Home Services",
  PAGE_SUBTITLE:
    'Connect with qualified, licenced electricians in your local area for the installation of electrical products, packages and services',
  FULLY_INSTALLED: 'Fully Installed Packages',
  SEE_ALL: 'See all packages',
  START_HERE: 'Start here - For Trades and Services',
  ABOUT_TITLE: 'Fully installed electrical products',
  ABOUT_DESCRIPTION: `With a wide range of fully installed electrical products and services available locally,\nMiddys Home services allows you to optimise, protect ond maintain your home or investment property.`,
  HOW_IT_WORKS: 'How it all works',
  FAQS: 'Frequently asked questions',
  SERVICING_METRO_ONLY: 'Currently Servicing Adelaide Metro Only ',
  METRO_DESCRIPTION:
    "With a wide range of home automation solutions, Middy's Home Services provides end-to-end services to manage your household seamlessly, connecting you to the latest electrical products and services available locally to allow you to optimise, protect and maintain your home or investment.",
  GET_NOTIFIED: 'Get notified',
  VIEW_ALL: 'View all',
  ADELAIDE_HEADING: "Middy's Home Services is Currently Servicing Adelaide Metro Only",
  METRO_SECTION_DESCRIPTION: `Simply choose from our wide range of fully installed electrical products, or search for a solution by room.\nSubmit an online query and you will be contacted by our nearest qualified licensed qualified electrician who will \n provide a FREE quote. Once you accept the quote, our electrician will arrive at the scheduled time to \ncomplete the job, then you can then finalise the payment once satisfied.`,
  BANNER_HEADING: 'Electrical Contractor or Installer?',
  BANNER_SUBHEADING:
    "Learn how Middy's Home Services can connect you to more local customers and save you time.",
  BANNER_BUTTON_LABEL: 'Register Now',
  SOLUTIONS_HEADING: 'Electrical Solutions By Room',
  WHY_CHOOSE: 'Why Choose a Fully Installed Electrical Product',
};

export const STEPS = [
  {
    title: 'Select Electrical \n Product or Service',
  },
  {
    title: 'Connect With A \n Local Electrician',
  },
  {
    title: 'Get The \n Job Done!',
  },
];

export const STEPS_SMALL = [
  {
    title: 'Select Electrical Product or Service',
  },
  {
    title: 'Connect With A Local Electrician',
  },
  {
    title: 'Get The Job Done!',
  },
];

export const WHY_CHOOSE = [
  {
    title: "Are Middy's quotes free?",
    text: "Yes, Middy's Home Services provides free quotes for all our packages and services. When you request a quote, a licensed electrician will assess your needs and provide a detailed estimate without any obligation to proceed. Our goal is to ensure you have all the necessary information to make an informed decision about your home improvement projects.",
  },
  {
    title: "How does Middy's assess and select tradies?",
    text: "Middy's ensures compliance through initial screening, licensing verification, onboarding, ongoing monitoring, and periodic re-evaluation.",
  },
  {
    title: 'Is a package available in my area?',
    text: "Middy's Home Services are currently available across most Adelaide metro postcodes as well as postocdes within central Mildura. Search your postcode at the top of the page to find out what packages are near you. You can also <a to={routes.registerInterest}>register your intertest</a> and receive alerts when Home Services come to your area.",
  },
  // {
  //   title: "Does Middy's help with 24/7 emergency repairs?",
  //   text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  // },
  {
    title: 'How do I cancel or update my job booking?',
    text: 'To cancel or update your job booking simply head to the Sorted App and speak directly to your selected Electrician. <a to={https://apps.apple.com/au/app/sorted/id1232960956}>Download Sorted on the App Store</a> or <a to={https://play.google.com/store/apps/details?id=com.sortedservices.sorted&hl=en}>Get it On Google Play</a>',
  },
  {
    title: 'I have feedback/complaint, where can I go?',
    text: `We value your feedback and are committed to providing the best service possible. If you have feedback or a complaint, you can reach out to us at hello@middys.com.au`,
  },
  {
    title: "Does Middy's offer warranty on material and labour?",
    text: "Middy's offer warranties on products used by the nominated trade specific to Middys Home Service Packages.",
  },
  {
    title: "I am a Middy's contractor, how I can sign-up to MTA?",
    text: "If you’re a Middy's trade customer looking to find out more and sign up for the Middy’s Trade Accelerator (MTA), Visit the <a to={https://www.middys.com.au/services/business-support/trade-accelerator}>MTA Sign-Up and information Page</a>",
  },
];

export const HIGHLIGHTED_PACKAGES = [
  { name: 'Fans', image: fans },
  { name: 'Lights', image: lights },
  { name: 'EV Charging', image: charging },
  { name: 'Switchboard Upgrade', image: switchboard },
  { name: 'General Electrical Services', image: electrical },
  { name: 'Solar', image: solar },
];

export const ROOMS = [
  { name: 'Kitchen', image: kitchen },
  { name: 'Bathroom', image: bathroom },
  { name: 'Outdoor', image: outside },
  { name: 'Smart Home', image: smartHome },
];

export const BUNDLES_PRIORITY = ['Fans', 'Lights', 'Home Automation', 'EV Charging'];
