import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './HowItWorksSectionStyles';

interface HowItWorksSectionProps {}

const HowItWorksSection: FC<HowItWorksSectionProps> = ({}) => {
  const styles = useStyles({});
  return (
    <div className={styles.howItWorksContainer}>
      <div className={styles.loadingHowItWorksTitle} />
      <div className={styles.howItWorksSteps}>
        {[...Array(4).fill(null)].map((_, idx) => (
          <div className={styles.howItWorksStep} key={idx}>
            <div className={styles.loadingStep} />
            <div className={styles.loadingStepTitle} />
            <div className={styles.loadingStepText} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorksSection;
