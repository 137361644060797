import { theme } from '../../theme/Theme';
import { Theme, makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { loadingAnimation, pageContainer } from '../../theme/GlobalStyles';
import homeBanner from '../../assets/home_banner.png';

const HEIGHT = 500;
const SMALL_HEIGHT = 360;
const LANDING_HEIGHT = 800;

interface StyleProps {
  imgPosition?: string;
  landing?: boolean;
  invertImage?: boolean;
  backgroundImage?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  backgroundContainer: ({ backgroundImage }) => ({
    position: 'relative',
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : theme.hero,
    backgroundSize: 'cover',
  }),
  backgroundWrapper: (props) => ({
    width: '100vw',
    display: 'flex',
    zIndex: 0,
    justifyContent: 'center',
    minHeight: '500px',
  }),
  backgroundImage2: (props) => ({
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    objectPosition: props?.imgPosition || 'center',
    objectFit: 'cover',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  }),
  trapezoid: {
    height: 250,
    zIndex: 2,
    width: '100%',
    background: '#ec008c',
    clipPath: 'polygon(0 0, 100% 25%, 100% 100%, 0% 100%)',
    alignSelf: 'flex-end',
    position: 'absolute',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: 350,
    },
  },
  backgroundImage: (props) => ({
    position: 'absolute',
    right: 0,
    height: props.landing ? LANDING_HEIGHT : HEIGHT,
    width: '100%',
    objectPosition: props?.imgPosition || 'top',
    objectFit: 'cover',
    zIndex: 1,
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
    transform: props.invertImage ? 'rotateY(180deg)' : 'none',
  }),
  darken: {
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    backgroundColor: '#000',
    opacity: 0.5,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  contentStart: {
    height: HEIGHT,
    width: '100vw',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  inset: {
    height: 260,
    width: '100vw',
  },
  children: {
    position: 'absolute',
    width: '100vw',
    top: 260,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      top: 200,
    },
  },
  textContainer: {},
  textWrapper: {
    position: 'relative',
    alignSelf: 'flex-end',
  },
  preHeader: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family2,
    textTransform: 'uppercase',
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  headline: {
    fontSize: theme.font.xxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  introText: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    maxWidth: 520,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  text: {
    fontSize: theme.font.xxxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  // Loading
  loadingPreHeader: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
  loadingHeadline: {
    width: 260,
    height: 40,
    backgroundColor: theme.colors.light,
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
  },
  loadingIntroText: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
  contentContainer: {
    zIndex: 5,
    width: '100%',
    height: '100%',
    padding: '2em 0',
    maxWidth: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexWrap: 'wrap-reverse',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      maxWidth: '90%',
    },
  },
  formWrapper: {
    flexGrow: 1,
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginLeft: theme.units.margin * 10,
    },
    [`@media (min-width: ${SIZES.md}px) and (max-width: ${SIZES.lg})px`]: {
      marginBottom: '2em',
    },
    display: 'flex',
  },
  collapsedFormContainer: {
    marginTop: '2em',
    padding: '1em',
    background: 'white',
    cursor: 'pointer',
  },
  arrowContainer: {
    background: theme.colors.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
});
