import React, { FC } from 'react';
import { useStyles } from './FullWidthBlueBannerStyles';
import ContentContainer from '../containers/ContentContainer';
import { Typography } from '@material-ui/core';
import { routes } from '../../Routes';
import ActionButton from '../actionButton/ActionButton';
import { LABELS } from '../bundleCard/BundleCardConstants';
import { ListBox } from '../listBox/ListBox';

interface FullWidthBlueBannerProps {
  heading: string;
  subheading?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
}

const FullWidthBlueBanner: FC<FullWidthBlueBannerProps> = ({
  heading,
  subheading,
  buttonAction,
  buttonLabel,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.registerInterestBanner}>
      <ContentContainer>
        <div className={styles.bannerContent}>
          <ListBox spacing={2}>
            <Typography variant="h3" className="title" style={{ color: 'white' }}>
              {heading}
            </Typography>
            {subheading && (
              <Typography style={{ color: 'white', fontSize: 14 }}>{subheading}</Typography>
            )}
          </ListBox>
          {buttonLabel && buttonAction && (
            <ActionButton onClick={buttonAction}>{buttonLabel}</ActionButton>
          )}
        </div>
      </ContentContainer>
    </div>
  );
};

export default FullWidthBlueBanner;
