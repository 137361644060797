import React, { FC } from 'react';
import { useStyles } from './QuoteFormStyles';
import { Box, Typography } from '@material-ui/core';
import {
  FIELD_LABELS,
  FIELDS,
  INITIAL_VALUES,
  INTEREST_OPTIONS,
  LABELS,
  TIMEFRAME_OPTIONS,
  VALIDATION_SCHEMA,
} from './QuoteFormConstants';
import { Field, Formik } from 'formik';
import MiddysTextField from '../../components/middysFields/MiddysTextField';
import MiddysSelect from '../../components/middysFields/middysSelect/MiddysSelect';
import FieldLabel from '../../components/fieldLabel/FieldLabel';
import { theme } from '../../theme/Theme';
import { ListBox } from '../../components/listBox/ListBox';
import Button from '../../components/button/Button';
import { TERMS_LABEL, TERMS_LINK } from '../../Constants';

interface QuoteFormProps {}

const QuoteForm: FC<QuoteFormProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <Box mb={3}>
        <Typography style={{ fontFamily: theme.font.family2 }} color="secondary" variant="h2">
          {LABELS.heading}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography style={{ fontFamily: theme.font.family2 }} color="secondary" variant="h4">
          {LABELS.subheading}
        </Typography>
      </Box>
      <Formik
        onSubmit={() => {}}
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
      >
        <ListBox spacing={2}>
          <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
            <MiddysTextField
              required
              name={FIELDS.firstName}
              placeholder={FIELD_LABELS.firstName}
              label={FIELD_LABELS.firstName}
            />
            <MiddysTextField
              required
              name={FIELDS.lastName}
              placeholder={FIELD_LABELS.lastName}
              label={FIELD_LABELS.lastName}
            />
          </ListBox>
          <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
            <MiddysTextField
              required
              name={FIELDS.mobile}
              placeholder="0000 000 000"
              label={FIELD_LABELS.mobile}
            />
            <MiddysTextField
              required
              name={FIELDS.email}
              placeholder={FIELD_LABELS.email}
              label={FIELD_LABELS.email}
            />
          </ListBox>
          <MiddysTextField
            required
            name={FIELDS.address}
            placeholder={FIELD_LABELS.address}
            label={FIELD_LABELS.address}
          />
          <ListBox direction="row" style={{ width: '100%' }} spacing={4}>
            <div style={{ flexBasis: '50%' }}>
              <FieldLabel required label={FIELD_LABELS.interestedIn} />
              <Field
                placeholder="Interest"
                name={FIELDS.interest}
                options={INTEREST_OPTIONS}
                component={MiddysSelect}
              />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <FieldLabel required label={FIELD_LABELS.timeframe} />
              <Field
                placeholder="Timeframe"
                name={FIELDS.timeframe}
                options={TIMEFRAME_OPTIONS}
                component={MiddysSelect}
              />
            </div>
          </ListBox>
          <MiddysTextField
            placeholder="Specific products/packages"
            name={FIELDS.notes}
            label={FIELD_LABELS.notes}
          />
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridColumnGap="1em"
          >
            <Typography color="textSecondary" variant="h6">
              {TERMS_LABEL}
              <a
                style={{ color: theme.colors.secondary }}
                target="_blank"
                href={TERMS_LINK}
                rel="noopener noreferrer"
              >
                <span color={theme.colors.textSecondary}>
                  <u>Middy's Privacy Policy</u>
                </span>
              </a>
            </Typography>
            <Button handlePress={() => {}}>Submit</Button>
          </Box>
        </ListBox>
      </Formik>
    </div>
  );
};

export default QuoteForm;
