import { makeStyles } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  formContainer: {
    boxShadow: '0px 4px 19px 0px #0000002B',
    padding: `40px 30px`,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      padding: `${theme.units.padding * 10}px ${theme.units.padding * 10}px`,
    },
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginLeft: theme.units.margin * 10,
    },
    background: 'white',
    width: '100%',
  },
});
