import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './MiddysFooterStyles';
import logoWhite from '../../assets/logos/middys-white.png';
import instagram from '../../assets/socials/instagram.png';
import facebook from '../../assets/socials/facebook.png';
import linkedIn from '../../assets/socials/linkedIn.png';
import sponsor1 from '../../assets/sponsors/sponsor1.png';
import sponsor2 from '../../assets/sponsors/sponsor2.png';
import sponsor3 from '../../assets/sponsors/sponsor3.png';
import sponsor4 from '../../assets/sponsors/sponsor4.png';
import poweredBy from '../../assets/poweredBy.png';
import { LABELS } from './MiddysFooterConstants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ActionButton from '../actionButton/ActionButton';
import ContentContainer from '../containers/ContentContainer';
import { useHistory } from 'react-router';
import { routes } from '../../Routes';
import { BundlePackage } from '../../models/bundles/Bundles';
import { theme } from '../../theme/Theme';
import { PRIORITY_ORDER, getIcon } from '../header/packagesMenu/PackagesMenuConstants';
import MiddysLink from '../link/MiddysLink';
import { groupBy } from 'lodash';
import { ListBox } from '../listBox/ListBox';
import { ArrowDownward, ExpandMore } from '@material-ui/icons';

interface MiddysFooterProps {
  showRegisterInterestBanner?: boolean;
  packages: BundlePackage[];
}

const MiddysFooter: FC<MiddysFooterProps> = ({ showRegisterInterestBanner, packages }) => {
  const styles = useStyles();
  const history = useHistory();
  const data = groupBy(packages, (p) => p.category!.title);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={styles.footerContainer}>
      {showRegisterInterestBanner && (
        <div className={styles.registerInterestBanner}>
          <ContentContainer>
            <div className={styles.bannerContent}>
              <Typography variant="h3" className="title" style={{ color: 'white' }}>
                {LABELS.REGISTER_INTEREST_BANNER_TEXT}
              </Typography>
              <ActionButton
                onClick={() => {
                  history.push(routes.registerInterest);
                }}
              >
                {LABELS.REGISTER_INTEREST}
              </ActionButton>
            </div>
          </ContentContainer>
        </div>
      )}
      <div className={styles.topFooter}>
        <div className={styles.footerContent}>
          <div className={styles.flexRow}>
            <img src={logoWhite} className={styles.middysLogoImg} alt="logo" />
          </div>
          <div className={styles.flexRow}>
            <img
              src={poweredBy}
              className={styles.poweredBy}
              alt="powered by sorted"
              onClick={() => window.open('https://www.sortedservices.com/', '_blank')}
            />
          </div>

          {/* Packages */}
          {isSmall ? (
            <Box mt={4}>
              {['Fans', ...PRIORITY_ORDER].map((p) => (
                <Accordion
                  style={{ background: 'transparent' }}
                  elevation={0}
                  classes={{ root: styles.accordionRoot }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{ color: 'white' }} fontSize="large" />}
                    classes={{ root: styles.accordionSummaryRoot }}
                  >
                    <Box width="100%" display="flex" justifyContent="space-between">
                      <Typography variant="h4" className="title" style={{ color: 'white' }}>
                        {p}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListBox spacing={2}>
                      {data[p].map((item) => (
                        <Box
                          onClick={() => {
                            history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
                          }}
                        >
                          <Typography className={styles.packageName}>
                            {item.packageName || item.standard.title}
                          </Typography>
                        </Box>
                      ))}
                    </ListBox>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ) : (
            <Box mt={6} display="flex" gridGap="2em" alignItems="flex-start">
              <ListBox flexBasis="max-content" spacing={2}>
                <MiddysLink to={{ pathname: routes.packages, search: `?filter=${'Fans'}` }}>
                  <Box display="flex" gridGap="0.5em">
                    <Typography className={styles.packageCategory} variant="h5">
                      {'Fans'}
                    </Typography>
                  </Box>
                </MiddysLink>
                {data['Fans'].map((item) => (
                  <Box
                    onClick={() => {
                      history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
                    }}
                  >
                    <Typography className={styles.packageName}>
                      {item.packageName || item.standard.title}
                    </Typography>
                  </Box>
                ))}
              </ListBox>
              <Box flexBasis="max-content" className={styles.packagesListContainer}>
                {PRIORITY_ORDER.map((p) => (
                  <Box className={styles.packageDetailsContainer}>
                    <MiddysLink to={{ pathname: routes.packages, search: `?filter=${p}` }}>
                      <Box display="flex" gridGap="0.5em">
                        <Typography className={styles.packageCategory} variant="h5">
                          {p}
                        </Typography>
                      </Box>
                    </MiddysLink>
                    {data[p].map((item) => (
                      <Box
                        onClick={() => {
                          history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
                        }}
                      >
                        <Typography className={styles.packageName}>
                          {item.packageName || item.standard.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
              {/* <Box>
            <Box mt={1} py={2} className={` ${styles.packageDetailsContainer}`}>
              <MiddysLink to={{ pathname: routes.packages, search: `?filter=Services` }}>
                <Box display="flex" gridGap="0.5em">
                  <img src={getIcon('Services')} />
                  <Typography variant="h5">{LABELS.services}</Typography>
                </Box>
              </MiddysLink>
              <MiddysLink to={routes.electricianQuote}>
                <Typography style={{ fontSize: theme.font.s }}>{LABELS.electrician}</Typography>
              </MiddysLink>
              <MiddysLink to={routes.solarQuote}>
                <Typography style={{ fontSize: theme.font.s }}>{LABELS.solar}</Typography>
              </MiddysLink>
            </Box>
          </Box> */}
            </Box>
          )}
          <Box mt={6} display="flex" width="100%" justifyContent="flex-end">
            <div className={styles.socialsContainer}>
              <div className={styles.socialsTitle}>{LABELS.FOLLOW}</div>
              <div className={styles.socialIcons}>
                <img
                  onClick={() => window.open(LABELS.FACEBOOK_LINK, '_blank')}
                  src={facebook}
                  className={styles.socialIcon}
                  alt="facebook"
                />
                <img
                  onClick={() => window.open(LABELS.INSTAGRAM_LINK, '_blank')}
                  src={instagram}
                  className={styles.socialIcon}
                  alt="instagram"
                />
                <img
                  onClick={() => window.open(LABELS.LINKEDIN, '_blank')}
                  src={linkedIn}
                  className={styles.socialIcon}
                  alt="linkedin"
                />
              </div>
            </div>
          </Box>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <div className={styles.footerContent}>
          <div className={styles.flexRowCentered}>
            <div className={styles.sponsors}>
              <img src={sponsor1} style={{ width: 42 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor2} style={{ width: 50 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor3} style={{ width: 70 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor4} style={{ width: 80 }} className={styles.sponsor} alt="sponsor" />
            </div>
            <div className={styles.cc}>{LABELS.CC}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddysFooter;
