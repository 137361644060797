import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import MIDDYS from './themes/MIDDYS';
import localStorage, { LocalStorageKeys } from '../services/LocalStorage';
import { SIZES } from '../Constants';

const themeCollection: ThemeType[] = [...MIDDYS];
const defaultTheme = themeCollection[0];
export enum ThemeKey {
  MIDDYS = 'MIDDYS',
}

export const getThemeKey = (): ThemeKey | undefined => {
  const result = localStorage.getItem(LocalStorageKeys.APP_THEME);
  if (result) return result as ThemeKey;
  return undefined;
};

export const setThemeKey = (themeKey: ThemeKey) =>
  localStorage.setItem(LocalStorageKeys.APP_THEME, themeKey);

const getTheme = (themeKey?: ThemeKey): ThemeType => {
  return {
    ...(themeCollection.find((theme) => theme.key === themeKey) || defaultTheme),
  };
};

export const theme = getTheme(getThemeKey());

interface ThemeType {
  key: string;
  logo: string;
  logoCircle: string;
  hero: string;
  colors: {
    light: string;
    lightFaded: string;
    lightGrey: string;
    black87: string;
    grey: string;
    darkGrey: string;
    primary: string;
    secondary: string;
    black38: string;
    black: string;
    label: string;
    borderColor: string;
    disabled: string;
    filterBackground: string;
    disabledText: string;
    buttonPrimary: string;
    black60: string;
    offerOverlay: string;
    inputBackground: string;
    inputText: string;
    error: string;
    navyBlue: string;
    textSecondary: string;
  };
  font: {
    family: string;
    family2: string;
    xxs: number;
    xs: number;
    s: number;
    m: number;
    l: number;
    xl: number;
    xxl: number;
    xxxl: number;
    buttonCase: string;
  };
  units: {
    padding: number;
    borderRadius: number;
    margin: number;
    iconHeight: number;
    iconWidth: number;
  };
}

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Open Sans',
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: theme.font.family,
        fontSize: theme.font.xxxl,
        fontWeight: 500,
      },
      h2: {
        fontFamily: theme.font.family,
        fontSize: theme.font.xxl,
        fontWeight: 500,
      },
      h3: {
        fontFamily: theme.font.family,
        fontSize: theme.font.xl,
        fontWeight: 500,
      },
      h4: {
        fontFamily: theme.font.family,
        fontSize: theme.font.l,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      h5: {
        fontFamily: theme.font.family,
        fontSize: theme.font.m,
        fontWeight: 300,
      },
      h6: {
        fontFamily: theme.font.family,
        fontSize: theme.font.s,
        fontWeight: 500,
      },
      colorSecondary: { color: theme.colors.secondary },
      colorTextSecondary: { color: theme.colors.textSecondary },
    },
  },
  palette: {
    primary: {
      main: '#0055a5',
    },
  },
  breakpoints: {
    values: SIZES,
  },
};

const appTheme = createTheme(themeOptions);

export default appTheme;
