import smartHome from '../../../assets/smart-home.svg';
import electrical from '../../../assets/electrical.svg';
import lights from '../../../assets/lights.svg';
import fans from '../../../assets/fans.svg';
import tools from '../../../assets/tools.svg';
import lightSwitch from '../../../assets/light-switch.svg';

export const LABELS = {
  close: 'Close menu',
  packages: 'Packages',
  services: 'Services',
  electrician: 'Electrician',
  solar: 'Solar',
  viewAll: 'View all',
};

export const PRIORITY_ORDER = [
  'Electrical Packages by Room',
  'Lights',
  'Switchboard Upgrades',
  'EV Charging',
];

export const getIcon = (type: string) => {
  switch (type) {
    case 'Electrical Packages by Room':
      return smartHome;

    case 'EV Charging':
      return electrical;

    case 'Lights':
      return lights;

    case 'Fans':
      return fans;

    case 'Services':
      return tools;

    case 'Switchboard Upgrades':
      return lightSwitch;

    default:
      return '';
  }
};
