import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { cardContainer, loadingAnimation, pageContainer, root } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
  },
  card: {
    ...cardContainer,
    height: 100,
  },
  allOffersText: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    color: theme.colors.buttonPrimary,
    marginTop: theme.units.margin * 8,
    marginBottom: theme.units.margin * 8,
  },
  offersGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 10,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',

    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  servicesGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 2fr',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    // [`@media (max-width: ${SIZES.lg}px)`]: {
    //   gridTemplateColumns: 'auto auto auto',
    //   gridGap: theme.units.margin,
    // },
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   gridTemplateColumns: 'auto auto',
    //   gridGap: theme.units.margin,
    // },
    // [`@media (max-width: ${SIZES.sm}px)`]: {
    //   gridTemplateColumns: 'auto',
    // },
    marginBottom: 100,
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.units.margin * 16,
    paddingBottom: theme.units.margin * 8,
    flexDirection: 'column',
  },
  subheading: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
  },
  title: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginTop: theme.units.margin * 4,
    marginBottom: theme.units.margin * 4,
    textTransform: 'capitalize',
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap',
    marginBottom: theme.units.margin * 8,
  },
  stepTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    fontWeight: 500,
    color: theme.colors.darkGrey,
    marginBottom: theme.units.margin * 2,
    marginTop: theme.units.margin * 6,
  },
  stepText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.s,
    color: theme.colors.black60,
    lineHeight: 1.6,
  },
  actionButton: {
    height: 40,
    minWidth: 150,
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 6,
    borderRadius: 5000,
    border: `${theme.colors.primary} 4px solid`,
    backgroundColor: theme.colors.primary,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minWidth: 120,
    },
  },
  categoriesRow: {},
  categoryContainer: {
    position: 'relative',
    width: '100%',
    border: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  imageContainer: {
    height: 180,
    width: '100%',
    ...loadingAnimation,
  },
  image: {
    width: '100%',
    height: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
  },
  categoryContentContainer: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  categoryTitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 2,
  },
  categoryDescription: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black87,
    marginBottom: theme.units.margin * 2,
  },
  sectionContainer: {
    backgroundColor: theme.colors.filterBackground,
    padding: theme.units.padding * 8,
    marginTop: theme.units.margin * 16,
    marginBottom: theme.units.margin * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: theme.units.padding * 4,
    },
  },
  sectionTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 8,
  },
  highlightedPackagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.units.margin * 6,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr',
    },
    columnGap: theme.units.margin * 10,
  },
  servicingContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.units.margin * 2,
  },
  mapImage: {
    maxHeight: 350,
    width: 'max-content',
    border: '1px solid black',
  },
  servingAdelaideContainer: {
    background: '#f5f5f5',
    padding: `${theme.units.margin * 10}px ${theme.units.margin * 20}px`,
    textAlign: 'center',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      padding: `${theme.units.margin * 10}px ${theme.units.margin * 5}px`,
    },
  },
  metroTextContainer: {
    maxWidth: 800,
    alignSelf: 'center',
  },
  solutionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '2em',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});
