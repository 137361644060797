import { makeStyles } from '@material-ui/core';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  registerInterestBanner: {
    background: theme.colors.primary,
    color: 'white',
    padding: `${theme.units.padding * 6}px 0`,
  },
  bannerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '1em',
  },
});
