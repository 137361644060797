import React, { FC } from 'react';
import HowItWorksSectionLoading from './HowItWorksSectionLoading';
import { useStyles } from './HowItWorksSectionStyles';
import FormattedText from '../formattedText/FormattedText';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface HowItWorksSectionProps {
  title: string;
  steps: { title: string; text?: string }[];
  loading?: boolean;
  altStyle?: boolean;
}

const HowItWorksSection: FC<HowItWorksSectionProps> = ({ title, steps, loading, altStyle }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const styles = useStyles({ altStyle, isSmall });

  return (
    <>
      {loading ? (
        <HowItWorksSectionLoading />
      ) : (
        <div className={styles.howItWorksContainer}>
          <div className={styles.howItWorksTitle}>{title}</div>
          <div className={styles.howItWorksSteps}>
            {steps.map((step, idx) => (
              <div className={styles.howItWorksStep}>
                <div className={styles.step}>{idx + 1}</div>
                <div className={styles.stepTitle}>
                  <FormattedText newLineSpace={0}>{step.title}</FormattedText>
                </div>
                {step.text && <div className={styles.stepText}>{step.text}</div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default HowItWorksSection;
